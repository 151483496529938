// 首页-社保
import { INSURANCE } from '../api'
import { METHOD, request } from '@/utils/request'

// 查询当前年的每个月投保成本数据
export async function getYearBill(params) {
  return request(`${INSURANCE}/tenantBill/getYearBill`, METHOD.GET, params)
}

// 待投保列表
export async function getIndexWaitInsure(params) {
  return request(`${INSURANCE}/staffInsureScheme/getIndexWaitInsure`, METHOD.GET, params)
}

// 待停保列表
export async function getIndexWaitStop(params) {
  return request(`${INSURANCE}/staffInsureScheme/getIndexWaitStop`, METHOD.GET, params)
}

// 投保中列表
export async function getIndexInsuring(params) {
  return request(`${INSURANCE}/staffInsureScheme/getIndexInsuring`, METHOD.GET, params)
}

// 增员人员列表
export async function getIndexIncrease(params) {
  return request(`${INSURANCE}/staffInsureScheme/getIndexIncrease`, METHOD.GET, params)
}

// 减员人员列表
export async function getIndexDecrease(params) {
  return request(`${INSURANCE}/staffInsureScheme/getIndexDecrease`, METHOD.GET, params)
}
